import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Collapse,
  NavbarToggler,
  NavLink,
} from "reactstrap";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import FeatherIcon from "feather-icons-react";
import Eveplaylogo from "../../assets/images/logo-dark.png";

const Privacypolicy = () => {
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navClass, setnavClass] = useState("");

  const toggle = () => setisOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  const scrollNavigation = () => {


    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass(" is-sticky");
    } else {
      setnavClass("");
    }
  };
  return (
    <React.Fragment>

<nav className={"navbar navbar-expand-lg navbar-landing fixed-top " + navClass} id="navbar" style={{backgroundColor:"black", color:"white"}}>
            <Container fluid className="custom-container">

                    <Link className="navbar-brand" to="/home">
                        <img src={Eveplaylogo} className="card-logo card-logo-dark" alt="logo dark" height="50" />
                    </Link>

                    <NavbarToggler className="navbar-toggler py-0 fs-20 text-body" onClick={toggle} type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </NavbarToggler>

                    <Collapse
                        isOpen={isOpenMenu}
                        className="navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <Scrollspy
                            offset={-18}
                            items={[
                                "hero",
                                "services",
                                "features",
                                "plans",
                                "reviews",
                                "team",
                                "contact",
                            ]}
                            currentClassName="active"
                            className="navbar-nav mx-auto mt-2 mt-lg-0"
                            id="navbar-example"
                        >

                <li className="nav-item">
                <NavLink className="fs-16" href="/home"  style={{color:"white"}}>
                Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="fs-16" href='/Privacypolicy' style={{color:"white"}}>
                  Privacy Policy
                </NavLink>
              </li>
               <li className="nav-item">
                <NavLink className="fs-16" href="/Contact" style={{color:"white"}}>
                Contact Us
                </NavLink>
              </li>
        
                           
                        </Scrollspy>

                
                    </Collapse>
                </Container> 
                
                
            </nav>

            <div className="page-content">
        <Container fluid>
   
          <Row className="justify-content-center">
            <Col lg={10}>
              <Card>
                <div className="bg-soft-info position-relative">
                  <CardBody className="p-5">
                    <div className="text-center">
                      <h3>PRIVACY POLICY</h3>
                      <p className="mb-0 text-muted">
                        Last update: 17 October, 2023
                      </p>
                    </div>
                  </CardBody>
                  <div className="shape">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      // xmlns:svgjs="http://svgjs.com/svgjs"
                      width="1440"
                      height="60"
                      preserveAspectRatio="none"
                      viewBox="0 0 1440 60"
                    >
                      <g mask='url("#SvgjsMask1001")' fill="none">
                        <path
                          d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                          style={{ fill: "var(--vz-card-bg-custom)" }}
                        ></path>
                      </g>
                      <defs>
                        <mask id="SvgjsMask1001">
                          <rect width="1440" height="60" fill="#ffffff"></rect>
                        </mask>
                      </defs>
                    </svg>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>PRIVACY POLICY OF EVENT PLAY</h5>
                      <p className="text-muted">
                      Event Play App is a service run by IQ Broadcast Limited. Your data is important. It's important to us,
                      and we understand that it's important to you. Event Play App is committed to safeguarding your information in
                      line with the latest data protection and privacy laws. If you have any questions, please don't hesitate to contact us at info@iqplay.tv
                      </p>
                      <p className="text-muted">
                      This privacy policy will only be applicable, and the obligations of IQ Broadcast Limited, owner of Event Play App, will only extend to,
                      information expressly collected by IQ Broadcast Limited from you. IQ Broadcast Limited is committed to maintaining your trust and confidence
                      as well as to provide you with the best
                      possible experience while visiting our App. By visiting our App, you are accepting our privacy policy and the practices described herein
                      </p>
        
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Consent</h5>
                      <p className="text-muted">
                      On using Event Play App, you agree to provide consent to our collection, use and sharing of your personal
                      information as described in this policy. In some cases, particularly if you reside in a country governed by a data protection regulation,
                      we may ask you to provide explicit consent to access our services before proceeding for further operations.
                      </p>
            
                    </div>
                  </div>



                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Information Collection and Use</h5>
                      <p className="text-muted">
                      Our Main purposes in collecting information including Personal Information or Information provided by you is
                      to allow you to use the Event Play App, to improve our services to you, identify and authenticate any access to the application and related services.
                      We may also use your email address to send updates, news, or other information regarding our services.
                      </p>
                      <p className="text-muted">
                      We may also collect information or Personal Information to track user behavior and preferences for internal analytics
                      of all Users of the App, Device IDs / Unique identifiers, usage statistics, traffic data, Your IP address, IMEI number,
                      domain names, access times, locations and using third party such as Google Analytics, Google Play Services, Google Analytics for Firebase. We collect the permission to access Wi-Fi,
                      Networks Connection and Network access only to allow app to connect to the internet to provide its functionality. 
                      </p>
            
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Log Data</h5>
                      <p className="text-muted">
                      We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information
                      (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol
                      (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your
                      use of the Service, Access Notification, and other statistics.
                      We may also use your information to evaluate your interest for various genres of content, services, offers, traffic patterns.
                      </p>
                      <p className="text-muted">
                      If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence
                      about your activities or postings on the Event Play App Services, we may collect and store such information.
                      </p>
                      <p className="text-muted">
                      We identify and use Your IP address to help diagnose any problems with our server, and to administer Event Play App.
                      Your IP address is also used to help identify you and to gather broad demographic information to customize your experience on the Event Play App.
                      </p>
            
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Service Providers</h5>
                      <p className="text-muted">
                      We may employ third-party companies and individuals due to the following reasons:
                      </p>
                      <ul className="text-muted vstack gap-2">
                        <li>To facilitate our Service;</li>
                        <li>To provide the Service on our behalf;</li>
                        <li>To perform Service-related services; or</li>
                        <li>To assist us in analyzing how our Service is used.</li>
                        <li>to manage our business needs, such as monitoring, analyzing, and improving the Services and the Sites’ performance and functionality.</li>
                        <li>to manage risk and protect the App, the Services and you from fraud by verifying your identity, and helping to detect and prevent fraud and abuse of the Sites or Services.</li>

             

             
                      
            
                      </ul>


                
            
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Cookies</h5>
                      <p className="text-muted">
                      This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries
                      that use “cookies” to collect information and improve their services. You have the option to either accept or
                      refuse these cookies and know when a cookie is being sent
                      to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                      </p>
                
            
                    </div>
                  </div>


                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Links to Other Sites</h5>
                      <p className="text-muted">
                      This Service may contain links to other sites. If you click on a
                      third-party link, you will be directed to that site / Stores.
                      Note that these external sites are not operated by us. Therefore,
                      we strongly advise you to review the Privacy Policy of these websites. We have no control
                      over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. 
                      </p>
                
            
                    </div>
                  </div>



                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Consent Withdrawing</h5>
                      <p className="text-muted">
                      For withdrawing your consent at any time during the tenure of your services with us,
                      you may choose to do so by sending us an e-mail. We shall review your request
                      and may ask you to verify your identity. Post verification we will withdraw the
                      consent for which request was made by you and stop any further processing of your personal information.
                      </p>
                
            
                    </div>
                  </div>



                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Children's Privacy</h5>
                      <p className="text-muted">
                      These Services do not address anyone under the age of 13. We do not knowingly
                      collect personally identifiable information from children under 13. In the case we discover
                      that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian
                      and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
                      </p>
                
            
                    </div>
                  </div>



                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Changes to This Privacy Policy</h5>
                      <p className="text-muted">
                      We may update our Privacy Policy from time to time.
                      Thus, you are advised to review this page periodically for any changes.
                      These changes are effective immediately after they are posted on this page
                      </p>
                
            
                    </div>
                  </div>


                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FeatherIcon
                        icon="check-circle"
                        className="text-success icon-dual-success icon-xs"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>Contact Us</h5>
                      <p className="text-muted">
                      If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:support@eventplay.co.uk">support@eventplay.co.uk</a>
                      </p>
                
            
                    </div>
                  </div>

                

      
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>







      <footer style={{ backgroundColor: "black", color: "white"}}>
  <Container fluid className="custom-container">
    <Row className="text-center text-sm-start align-items-center mt-6">
      <Col sm={6}>
        <div style={{ marginTop: "40px" }}>
          <div>
            <img src={logodark} className="card-logo card-logo-dark" alt="logo dark" height="60px"  style={{ marginBottom: "30px" }} />

          </div>
        </div>
      </Col>
      <Col sm={3}>
        <div style={{ marginTop: "20px" }}>
          <p className="copy-rights md-12">
            {new Date().getFullYear()} ©EventPlay
          </p>
        </div>
      </Col>
      {/* <Col sm={3}>
        <div className="text-sm-end mt-3 mt-sm-0">
          <ul className="list-inline mb-0 footer-social-link">
            <li className="list-inline-item">
              <Link to="#" className="avatar-xs d-block">
                <div className="avatar-title rounded-circle">
                  <i className="ri-facebook-fill"></i>
                </div>
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="#" className="avatar-xs d-block">
                <div className="avatar-title rounded-circle">
                  <i className="ri-instagram-fill"></i>
                </div>
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="#" className="avatar-xs d-block">
                <div className="avatar-title rounded-circle">
                  <i className="ri-twitter-fill"></i>
                </div>
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="#" className="avatar-xs d-block">
                <div className="avatar-title rounded-circle">
                  <i className="ri-youtube-fill"></i>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </Col> */}
    </Row>
  </Container>
</footer>

    </React.Fragment>
  );
};

export default Privacypolicy;
