import React, { useEffect, useState } from "react";
import { useNavigate,Navigate } from "react-router-dom";


const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isSet, setIsSet] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBanners, setIsBanners] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isSettingspage, setisSettingspage] = useState(false);
    const [isPlatform, setIsPlatform] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);
    const [isAdvertisment, setisAdvertisment] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);


    // Apps
    // const [isEmail, setEmail] = useState(false);
    // const [isSubEmail, setSubEmail] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [isUsers, setIsUsers] = useState(false);
    const [isCountry, setIsCountry] = useState(false);
    const [isSponser, setisSponser] = useState(false);
    const [IsBottomAd, setIsBottomAd] = useState(false);
    const [Istextad, setIstextad] = useState(false);
    const [Ispackagead, setIspackagead] = useState(false);
    const [isContinent, setIsContinent] = useState(false);
    // const [isProjects, setIsProjects] = useState(false);
    // const [isTasks, setIsTasks] = useState(false);
    // const [isCRM, setIsCRM] = useState(false);
    // const [isCrypto, setIsCrypto] = useState(false);
    // const [isInvoices, setIsInvoices] = useState(false);
    // const [isSupportTickets, setIsSupportTickets] = useState(false);
    // const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    // const [isJobs, setIsJobs] = useState(false);
    // const [isJobList, setIsJobList] = useState(false);
    // const [isCandidateList, setIsCandidateList] = useState(false);


    // Authentication
    // const [isSignIn, setIsSignIn] = useState(false);
    // const [isSignUp, setIsSignUp] = useState(false);
    // const [isPasswordReset, setIsPasswordReset] = useState(false);
    // const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    // const [isLockScreen, setIsLockScreen] = useState(false);
    // const [isLogout, setIsLogout] = useState(false);
    // const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    // const [isVerification, setIsVerification] = useState(false);
    // const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);


    // Charts
    // const [isApex, setIsApex] = useState(false);

    // // Multi Level
    // const [isLevel1, setIsLevel1] = useState(false);
    // const [isLevel2, setIsLevel2] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'User') {
            setIsUser(false);
        }
        if (isSet !== 'Setting') {
            setIsSet(false);
        }

        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'Banners') {
            setIsBanners(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'Settingpage') {
            setisSettingspage(false);
        }
        if (iscurrentState !== 'platform') {
            setIsPlatform(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState !== 'Advertisment') {
            setisAdvertisment(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isUser,
        isSet,
        isAuth,
        isPages,
        isBanners,
        isBaseUi,
        isSettingspage ,
        isPlatform,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel,
        isAdvertisment
    ]);
 
      
    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboards",
            icon: "ri-dashboard-2-line",
            link: "/cmsdashboard",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
            subItems: [
                // { id: "dashboard", label: "DashBoard", link: "/dashboard", parentId: "platform" },
                { id: "MHTV CMS", label: "MHTV DASHBOARD", link: "/cmsdashboard", parentId: "platform" },
            
            ],       
        },
        {
            id: "Platform Page",
            label: "Platforms",
            icon: " ri-code-s-slash-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsPlatform(!isPlatform);
                setIscurrentState('platform');
                updateIconSidebar(e);
            },
            stateVariables: isPlatform,
            subItems: [
                { id: "Add platform", label: "Add Platform", link: "/addplatforms", parentId: "platform" },
                { id: "platform page", label: "Platform List", link: "/platformlist", parentId: "platform" },
         
            ],
        },
        { 
            id: "myhometv",
            label: "Packages",
            icon: "ri-apps-2-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsApps(!isApps);
                setIscurrentState('Apps');
                updateIconSidebar(e);
            },
            stateVariables: isApps,
            subItems: [
                { id: 1, label: "Continent Restrictions", link: "/Continentlist", parentId: "continentlist" },

             
        
                {
                    id: "countryrestriction",
                    label: "Country Restrictions",
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsCountry(!isCountry);
                    },
                    parentId: "country",
                    stateVariables: isCountry,
                    childItems: [
                        { id: 1, label: "Add Country", link: "/addcountry", parentId: "addcountrylist" },
                        { id: 2, label: "Country List", link: "/countrylist", parentId: "countrylist" },
              
                    ]
                },


                {
                    id: "appsecommerce",
                    label: "Package",
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsEcommerce(!isEcommerce);
                    },
                    parentId: "apps",
                    stateVariables: isEcommerce,
                    childItems: [
                        { id: 1, label: "Add Package", link: "/addpackage", parentId: "apps" },
                        { id: 2, label: "Package List", link: "/packagelist", parentId: "apps" },
              
                    ]
                },
              
            ],
        },
       
        {
            id: "pages",
            label: "Category",
            icon: "ri-pages-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsPages(!isPages);
                setIscurrentState('Pages');
                updateIconSidebar(e);
            },
            stateVariables: isPages,
            subItems: [
                { id: 1, label: "Add category", link: "/addcategory", parentId: "pages" },
                { id: 2, label: "Category List", link: "/categorylist", parentId: "pages" },
            ],
        },
        {
            id: "banners",
            label: "Banners",
            icon: "ri-pages-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsBanners(!isBanners);
                setIscurrentState('Banners');
                updateIconSidebar(e);
            },
            stateVariables: isBanners,
            subItems: [
                { id: 1, label: "Add banner", link: "/addbanners", parentId: "banners" },
                { id: 2, label: "Banner List", link: "/bannerlist", parentId: "banners" },
            ],
        },
        {
            id: "maps",
            label: "Channels",
            icon: "ri-slideshow-3-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsMaps(!isMaps);
                setIscurrentState('Maps');
                updateIconSidebar(e);
            },
            stateVariables: isMaps,
            subItems: [
                { id: 1, label: "Add channel", link: "/addchannel" },
                { id: 2, label: "Channel list", link: "/channellist" },
                { id: 3, label: "Archive", link: "/Archivelist" },
        
            ],
        },

        // {
        //     id: "multilevel",
        //     label: "Advertisment",
        //     icon: "ri-video-fill",
        //     link: "/#",
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsMultiLevel(!isMultiLevel);
        //         setIscurrentState('MuliLevel');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: isMultiLevel,
        //     subItems: [
        //         { id: 1, label: "Add Advertisment", link: "/advertisment" },
        //         { id: 2, label: "Advertisment list", link: "/advertismentlist" },
               
        //     ],
        // },
        
        {
            id: "Advertisment",
            label: "Advertisments",
            icon: "ri-video-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setisAdvertisment(!isAdvertisment);
                setIscurrentState('Advertisment');
                updateIconSidebar(e);
            },
            stateVariables: isAdvertisment,
            subItems: [
                {
                    id: "Sponsor Logo",
                    label: "Sponsor Logo",
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setisSponser(!isSponser);
                    },
                    parentId: "sponser",
                    stateVariables: isSponser,
                    childItems: [
                        { id: 1, label: "Add Sponsor", link: "/addsponserlogo", parentId: "AddSponsor" },
                        { id: 2, label: "Sponsor List", link: "/sponserlist", parentId: "Sponsorlist" },
              
                    ]
                },
                {
                    id: "bottom Ad",
                    label: "Bottom Page Ad",
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsBottomAd(!IsBottomAd);
                    },
                    parentId: "bottom",
                    stateVariables: IsBottomAd,
                    childItems: [
                        { id: 1, label: "Add Bottom Ad", link: "/addbottomad", parentId: "AddbottomAd" },
                        { id: 2, label: "Bottom Ad List", link: "/bottomlist", parentId: "Bottomadlist" },
              
                    ]
                },
                {
                    id: "textad",
                    label: "Text Ad",
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIstextad(!Istextad);
                    },
                    parentId: "Textad",
                    stateVariables: Istextad,
                    childItems: [
                        { id: 1, label: "Add Text Ad", link: "/addtext", parentId: "Addtextad" },
                        { id: 2, label: "Text Ad List", link: "/textlist", parentId: "Textlist" },
              
                    ]
                },
                {
                    id: "packagead",
                    label: "Package Selection Ad",
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIspackagead(!Ispackagead);
                    },
                    parentId: "packagead",
                    stateVariables: Ispackagead,
                    childItems: [
                        { id: 1, label: "Add Package Selection", link: "/Addpackageselection", parentId: "addpackagead" },
                        { id: 2, label: "Package Selection List", link: "/packageselectionlist", parentId: "packagelistad" },
              
                    ]
                },
                { id: 1, label: "Advertisment Archive", link: "/adarchive", parentId: "Advertarchive" },
                  
            ],       
        },
        { 
            id: "Userlist",
            label: "Users",
            icon: "ri-apps-2-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsUser(!isUser);
                setIscurrentState('User');
                updateIconSidebar(e);
            },
            stateVariables: isUser,
            subItems: [

                { id: 1, label: "User List", link: "/userlist" },
                { id: 2, label: "User favourite list", link: "/userfavourite" },
                { id: 3, label: "User Watch history", link: "/userhistory" },

                {
                    id: "User age group",
                    label: "User age group",
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsUsers(!isUsers);
                    },
                    parentId: "apps",
                    stateVariables: isUsers,
                    childItems: [
                        { id: 2, label: "Add age group", link: "/addagegroup", parentId: "apps" },
                        { id: 1, label: "User age group list", link: "/user_age_grouplist", parentId: "apps" },
                    ]
                },
              
            ],
        },
       
 


        {
            id: "setting Page",
            label: "Settings",
            icon: "ri-settings-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAdvanceUi(!isAdvanceUi);
                setIscurrentState('AdvanceUi');
                updateIconSidebar(e);
            },
            stateVariables: isAdvanceUi,
            subItems: [
                { id: "Add settings", label: "App Settings", link: "/addsettings", parentId: "advanceUi" },
                { id: "Settings Page", label: "Setting List", link: "/settingss", parentId: "advanceUi" },
            ],
        },

        // {
        //     id: "Settingspage",
        //     label: "Settings",
        //     icon: "ri-settings-line",
        //     link: "/#",
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsSet(!isSet);
        //         setIscurrentState('Setting');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: isSet,
        //     subItems: [
        //       {
        //             id: "Settingspage",
        //             label: "App Settings",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setisSettingspage(!isSettingspage);
        //             },
        //             parentId: "Settinng",
        //             stateVariables: isSettingspage,
        //             childItems: [
        //                 { id: 1, label: "Add App Settings", link: "/addsettings", parentId: "addsettings" },
        //                 { id: 2, label: "App Setting List", link: "/settingss", parentId: "settingss" },
              
        //             ]
        //         },



        //         {
        //             id: "Settings",
        //             label: "settings",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setIsEcommerce(!isEcommerce);
        //             },
        //             parentId: "apps",
        //             stateVariables: isEcommerce,
        //             childItems: [
        //                 { id: 1, label: "Add Settings", link: "/addsettings", parentId: "apps" },
        //                 { id: 2, label: "Setting List", link: "/settinglist", parentId: "apps" },
              
        //             ]
        //         },
              
        //     ],
        // },
       
  


   
        
        

    ];
    return <React.Fragment>{menuItems}</React.Fragment>
    
   
        
    
    
    
};




export default Navdata;