import React, { useEffect, useState,useRef } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form,CardHeader,UncontrolledTooltip} from 'reactstrap';
import withRouter from '../../Components/Common/withRouter';
import {  Link, useNavigate} from 'react-router-dom';
import { Collapse, NavbarToggler, NavLink } from "reactstrap";
import Scrollspy from "react-scrollspy";
import { Swiper, SwiperSlide } from "swiper/react";
import CountUp from "react-countup";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Pagination, Navigation,Autoplay } from "swiper";
import img8 from "../../assets/images/small/img-8.jpg";
import img9 from "../../assets/images/small/img-9.jpg";
import Android from "../../assets/images/small/Android.png";
import ios from "../../assets/images/small/ios.png";
import Banner from "../../assets/images/small/Banner.png";
import Position from 'rsuite/esm/Overlay/Position';
import app from '../../App.css';
import phone from "../../assets/images/phone.png";

import Eveplaylogo from "../../assets/images/logo-dark.png";
import phoneimg from "../../assets/images/phoneimage.png";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';



const Login = (props) => {


  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navClass, setnavClass] = useState("");




  const tawkMessengerRef = useRef();

 
  const toggle = () => setisOpenMenu(!isOpenMenu);


  



     



    document.title = "Event Play";
    return (
        <React.Fragment>

          <div>

        
            {/* <ParticlesAuth> */}

            <nav className={"navbar navbar-expand-lg navbar-landing fixed-top " + navClass} id="navbar" style={{backgroundColor:"black", color:"white"}}>
            <Container fluid className="custom-container">

                    <Link className="navbar-brand" to="/home">
                        <img src={Eveplaylogo} className="card-logo card-logo-dark" alt="logo dark" height="50" />
                    </Link>

                    <NavbarToggler className="navbar-toggler py-0 fs-20 text-body" onClick={toggle} type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </NavbarToggler>

                    <Collapse
                        isOpen={isOpenMenu}
                        className="navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <Scrollspy
                            offset={-18}
                            items={[
                                "hero",
                                "services",
                                "features",
                                "plans",
                                "reviews",
                                "team",
                                "contact",
                            ]}
                            currentClassName="active"
                            className="navbar-nav mx-auto mt-2 mt-lg-0"
                            id="navbar-example"
                        >

                <li className="nav-item">
                <NavLink className="fs-16" href="/home"  style={{color:"white"}}>
                Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="fs-16" href='/Privacypolicy' style={{color:"white"}}>
                  Privacy Policy
                </NavLink>
              </li>
               <li className="nav-item">
                <NavLink className="fs-16" href="/Contact" style={{color:"white"}}>
                Contact Us
                </NavLink>
              </li>
        
                           
                        </Scrollspy>

                
                    </Collapse>
                </Container> 
                
                
            </nav>



            
<section className='section'>
  <div className="auth-page-content">
    <Container fluid className="custom-container">
    <Row className="d-flex justify-content-center">
  <Col lg={9}>
    <Swiper 
      pagination={{ clickable: true, dynamicBullets: true }} 
      modules={[Pagination, Autoplay]} 
      loop={false} 
      autoplay={{ delay: 2500, disableOnInteraction: false }} 
      className="mySwiper swiper pagination-dynamic-swiper rounded"
    >
      <div className="swiper-wrapper">
        <SwiperSlide>
          <img src={img8} alt="" className="img-fluid" />
        </SwiperSlide>
      </div>
    </Swiper>
  </Col>
</Row>



            <Row className="align-items-center gy-4" style={{backgroundColor:"white"}}>
              <Col lg={6} sm={12} className="col-lg-6 order-2 order-lg-1">
              <div className="text-muted mt-5 mt-lg-0">
              <h1 className="mb-3 ff-secondary fw-bold text-capitalize lh-base" style={{color:"#E21E54"}}  >
              Event Play    
                </h1>
                <h3>
                Revolutionizing Event Experiences</h3>
              
                <p className="ff-secondary mb-2" style={{fontSize:"18px"}}>
                Event Play is the ultimate platform for clients to experience events, concerts, conferences, festivals, community events and more in a private and exclusive way. With EventPlay, you can stream your events live on any device, ensuring a secure and immersive experience from anywhere. Enjoy real-time access to every moment without sacrificing privacy, making it easier to stay engaged with the action as it unfolds.
                </p>

              </div>
            
            </Col>
            <Col className="col-lg-6 col-sm-7 col-10 ms-lg-auto mx-auto order-1 order-lg-2">
              <div>
              <img src={phoneimg} alt="" className="img-fluid" height="auto" style={{maxWidth:"90%"}}   />

               
              </div>
            </Col>
          </Row>
<h1 style={{color:"black"}}>Features</h1>
<Row className="g-lg-5 g-4 g-3">
    

    
                               <Col lg={4} className="text-center">
                                

 
                                <Card className="card-animate border shadow-none">
                                    <CardBody className="py-5 px-4">
                                        {/* <img src={item.img} alt="" height="55" className="mb-3 pb-2" /> */}
                                        <i className="ri-tv-fill fs-48" style={{color:"#E21E54"}}></i>
                                        <h4>Live Event Streaming</h4>
                                        <p style={{fontSize:"16px"}} className="text-muted pb-1">Witness your events unfold in real time, no matter where you are. Stream high-quality video directly to your
                                          mobile device, bringing the excitement of the event right to your fingertips. 
</p>
                                        {/* <Link to="#" className={item.bgColor ? "btn btn-info" : "btn btn-soft-info" }>Connect Wallet</Link> */}
                                    </CardBody>
                                </Card>
                                
                            </Col>
                            <Col lg={4} className="text-center">
                                

 
                                <Card className="card-animate border shadow-none">
                                    <CardBody className="py-5 px-4">
                               
                                        <i className="ri-camera-2-fill fs-48" style={{color:"#E21E54"}}></i>
                                        <h4>On-Demand Viewing</h4>
                                        <p  style={{fontSize:"16px"}} className="text-muted pb-1">Can't catch the event live? No problem! Event Play allows you to access recorded streams and watch them at your convenience.
                                          Relive the highlights, keynote speeches, and engaging sessions whenever you want.
</p>
                                        {/* <Link to="#" className={item.bgColor ? "btn btn-info" : "btn btn-soft-info" }>Connect Wallet</Link> */}
                                    </CardBody>
                                </Card>
                                
                            </Col>
                            <Col lg={4} className="text-center">
                                




                                <Card className="card-animate border shadow-none">
                                    <CardBody className="py-5 px-4">
                                    <i className=" ri-calendar-todo-fill fs-48" style={{color:"#E21E54"}}></i>
                                        <h4>Event Schedule</h4>
                                        <p style={{fontSize:"16px"}} className="text-muted pb-1">EventPlay makes event schedules seamless. Whether you're Viewing events, concerts, conferences, festivals, community events, our platform allows users to easily view events schedule.
</p>
                                        {/* <Link to="#" className={item.bgColor ? "btn btn-info" : "btn btn-soft-info" }>Connect Wallet</Link> */}
                                    </CardBody>
                                </Card>
                                
                            </Col>
                <Row className="custom-footer bg-white py-5 position-relative" style={{backgroundColor:"white"}}>
                 
                       <Col lg={6} sm={12} >
                            <div>
                                <img src={Banner} alt="" className="img-fluid" /> 
                            </div>
                        </Col>
                        <Col lg={6} className="order-2 order-lg-2">
                            <div className="text-muted">
                                <h1 style={{color:"#E21E54"}}>Download Our App</h1>
                                <h4 className="mb-3">START YOUR EVENT TODAY</h4>
                                <p  style={{fontSize:"16px"}} className="text-muted pb-1" >Experience the future of event participation with our App.
                                  Download the app today and immerse yourself in the excitement, knowledge, and networking opportunities that
                                  your private events offer. Stay connected, stay engaged,
                                  and make the most of every event moment!</p>
                               
                           
                                <div>
    <a className="navbar-brand" href='https://play.google.com/store/apps/details?id=uk.co.eventplay1&hl=en-IN' target='_blank' style={{display:"inline-block",marginRight:"10px"}}
   > 
        <img src={Android}  alt="logo dark" height="100" />
    </a>
    <a className="navbar-brand" href='https://apps.apple.com/az/app/event-play/id6450517317' target='_blank' style={{display:"inline-block",marginRight:"10px"}}
   > 
        <img src={ios}  alt="logo dark" height="40" />
    </a>
</div>
  

                                <Row>

                                  
                               
                                </Row>
                   
                            </div>
                        
                  
                        </Col>
                       


                    </Row><br/><br/>                 
 </Row>


 
</Container>
 </div>
</section>




<br/><br/><br/><br/>


<div>
      <TawkMessengerReact
        propertyId="66eabb9d4cbc4814f7da13d6"
        widgetId="1i82epo8u"
        ref={tawkMessengerRef}
      />
    </div>


<footer style={{ backgroundColor:"black", color: "white" }}>
  <Container fluid className="custom-container">
    <Row className="text-center text-sm-start align-items-center mt-6">
      <Col sm={6}>
        <div style={{ marginTop: "40px" }}>
          <div>
            <img src={Eveplaylogo} className="card-logo card-logo-dark" alt="logo dark" height="60px" style={{ marginBottom: "30px" }} />
          </div>
        </div>
      </Col>
      <Col sm={3}>
        <div style={{ marginTop: "20px" }}>
          <p className="copy-rights md-12"> Copyright
             © {new Date().getFullYear()} | EventPlay 
          </p>
        </div>
      </Col>
     
    </Row>
  </Container>
</footer>

            </div>
        </React.Fragment>
    );
};
export default withRouter(Login);