import React, { useEffect, useState,useRef } from 'react';

import { Col, Container, Form, Row } from 'reactstrap';
import logodark from "../../assets/images/logo-dark.png";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import logolight from "../../assets/images/logo-light.png";
import { Button} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Eveplaylogo from "../../assets/images/logo-dark.png";

import {
    Collapse,
    NavbarToggler,
    NavLink,
  } from "reactstrap";

  import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Contact = () => {
    const [navClass, setnavClass] = useState("");
    const [isOpenMenu, setisOpenMenu] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [subject, setsubject] = useState("");
    const [message, setmessage] = useState("");







    const toggle = () => setisOpenMenu(!isOpenMenu);

    useEffect(() => {
      window.addEventListener("scroll", scrollNavigation, true);
    });
  








      const tawkMessengerRef = useRef();

      const handleMinimize = () => {
          tawkMessengerRef.current.minimize();
      };
    





    const scrollNavigation = () => {
  
  
      var scrollup = document.documentElement.scrollTop;
      if (scrollup > 50) {
        setnavClass(" is-sticky");
      } else {
        setnavClass("");
      }
    };

    return (
        <React.Fragment>
              <div>

      </div>
      <nav className={"navbar navbar-expand-lg navbar-landing fixed-top " + navClass} id="navbar" style={{backgroundColor:"black", color:"white"}}>
            <Container fluid className="custom-container">

                    <Link className="navbar-brand" to="/home">
                        <img src={Eveplaylogo} className="card-logo card-logo-dark" alt="logo dark" height="50" />
                    </Link>

                    <NavbarToggler className="navbar-toggler py-0 fs-20 text-body" onClick={toggle} type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </NavbarToggler>

                    <Collapse
                        isOpen={isOpenMenu}
                        className="navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <Scrollspy
                            offset={-18}
                            items={[
                                "hero",
                                "services",
                                "features",
                                "plans",
                                "reviews",
                                "team",
                                "contact",
                            ]}
                            currentClassName="active"
                            className="navbar-nav mx-auto mt-2 mt-lg-0"
                            id="navbar-example"
                        >

                <li className="nav-item">
                <NavLink className="fs-16" href="/home"  style={{color:"white"}}>
                Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="fs-16" href='/Privacypolicy' style={{color:"white"}}>
                  Privacy Policy
                </NavLink>
              </li>
               <li className="nav-item">
                <NavLink className="fs-16" href="/Contact" style={{color:"white"}}>
                Contact Us
                </NavLink>
              </li>
        
                           
                        </Scrollspy>

                
                    </Collapse>
                </Container> 
                
                
            </nav><br/><br/><br/>
            <section className="section" id="contact">
                <Container>


                    <Row className="justify-content-center">
                        <Col lg={8}>
                       
                        </Col>
                    </Row><br/>
                    

                    <Row  className="justify-content-center" style={{backgroundColor:"white"}}>
                    
                           <div className="text-center mb-5">
                                <h1 className="mb-3 fw-semibold" style={{color:"#E21E54"}} >Get In To Touch</h1>
                                <h4 className="text-center mb-5">For Queries Write us : <a href="mailto:support@eventplay.co.uk">support@eventplay.co.uk </a></h4>
                            </div>
                    </Row>
                    <Row className="justify-content-center">
  <Col lg={8} className="d-flex justify-content-center">
    <Button href="mailto:support@eventplay.co.uk">Contact Us</Button>
  </Col>
</Row>

                    
                </Container>





                <div>
      <TawkMessengerReact
        propertyId="66eabb9d4cbc4814f7da13d6"
        widgetId="1i82epo8u"
        ref={tawkMessengerRef}
      />
    </div>












            </section><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>







            <footer style={{ backgroundColor: "black", color: "white"}}>
  <Container fluid className="custom-container">
    <Row className="text-center text-sm-start align-items-center mt-6">
      <Col sm={6}>
        <div style={{ marginTop: "40px" }}>
          <div>
            <img src={logodark} className="card-logo card-logo-dark" alt="logo dark" height="60px"  style={{ marginBottom: "30px" }} />

          </div>
        </div>
      </Col>
      <Col sm={3}>
        <div style={{ marginTop: "20px" }}>
          <p className="copy-rights md-12">
            {new Date().getFullYear()} ©EventPlay
          </p>
        </div>
      </Col>
      {/* <Col sm={3}>
        <div className="text-sm-end mt-3 mt-sm-0">
          <ul className="list-inline mb-0 footer-social-link">
            <li className="list-inline-item">
              <Link to="#" className="avatar-xs d-block">
                <div className="avatar-title rounded-circle">
                  <i className="ri-facebook-fill"></i>
                </div>
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="#" className="avatar-xs d-block">
                <div className="avatar-title rounded-circle">
                  <i className="ri-instagram-fill"></i>
                </div>
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="#" className="avatar-xs d-block">
                <div className="avatar-title rounded-circle">
                  <i className="ri-twitter-fill"></i>
                </div>
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="#" className="avatar-xs d-block">
                <div className="avatar-title rounded-circle">
                  <i className="ri-youtube-fill"></i>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </Col> */}
    </Row>
  </Container>
</footer>


















        </React.Fragment>
    );
};

export default Contact;