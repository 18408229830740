import React, { useState, useEffect } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

//import images
import github from "../../assets/images/brands/github.png";
import lg from "../../assets/images/brands/lg.png";
import roku from "../../assets/images/brands/roku.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";



import { Link } from 'react-router-dom';

const WebAppsDropdown = () => {

    const [settingList, setsettingList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredDatas, setFilteredData] = useState([]);
    const [isWebAppDropdown, setIsWebAppDropdown] = useState(false);
    const toggleWebAppDropdown = () => {
        setIsWebAppDropdown(!isWebAppDropdown);
    };


  














    return (
        <React.Fragment>
            <Dropdown isOpen={isWebAppDropdown} toggle={toggleWebAppDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle tag="button" type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-category-alt fs-22'></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
                    <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0 fw-semibold fs-15"> Platform </h6>
                            </Col>
                            {/* <div className="col-auto">
                                <Link to="#" className="btn btn-sm btn-soft-info"> View All Apps
                                    <i className="ri-arrow-right-s-line align-middle"></i></Link>
                            </div> */}
                        </Row>
                    </div>

                    <div className="p-2">
                        <div className="row g-0">
                     
                        <Col>
                    <div className="row">
                        {settingList.map((platform, index) => (
                        <div className="col-md-4" key={index}>
                            <a className="dropdown-icon-item" href={platform.app_store_link}
                            target='blank'>
                            {platform.platform_icon && (
                                <img src={platform.platform_icon} alt={platform.platform_name} />
                            )}
                            <span>{platform.platform_name}</span>
                            </a>
                        </div>
                        ))}
                    </div>
                    </Col>



                        </div>

                        <div className="row g-0">
                        
                           
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default WebAppsDropdown;