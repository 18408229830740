import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../pages/Authentication/Login";
import Privacypolicy from "../pages/Privacypolicy/Privacypolicy";
import Contactpage from "../pages/Contactpage/Contact";






const isUserLoggedIn = window.sessionStorage.getItem('userSession') !== null;

const authProtectedRoutes = [
  {
    path: "/",
    exact: true,
    component: isUserLoggedIn ? <Navigate to="/Eventhome" /> : <Navigate to="/home" />,
  },
  { path: "*", component: <Navigate to="/home" /> },
];


const publicRoutes = [
  { path: "/home", component: <Login /> },
  { path: "/Privacypolicy", component: <Privacypolicy /> },
  { path: "/Contact", component: <Contactpage /> },

 
];

export { authProtectedRoutes, publicRoutes };

